import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import Arrow from '@cuidardigital/commons/assets/img/arrow_back-black.png'
import { Col, Grid } from '@cuidardigital/commons/components/Grid/grid'
import { Loader } from '@cuidardigital/commons/components/Loader'
import {
	deleteSecretary,
	getSecretaries,
	postSecretaryCreation,
	reactivateSecretary,
	updateSecretaryByDoctor
} from '@cuidardigital/commons/services/secretary.services'
import IAssistant from '@cuidardigital/commons/types/IAssistant'
import IModalMessage from '@cuidardigital/commons/types/IModalMessage'
import IModalProd from '@cuidardigital/commons/types/IModalProp'
import { store } from '../../stores/app'
import TemplatePage from '../../templates/Page'
import { initUserSession } from '../../utils/session'
import { CollapseAssistant as Collapse } from '../Collapse'
import MessageModal from '../Modal'
import Confirm from '../Modal/confirm'
import AssistantModal from '../Modal/newAssistant'

import {
	Button,
	ColAssistant,
	ColBreadCrumb,
	ComponentWrapper,
	LinkPage,
	NoContentWrapper,
	Span,
	WrapperGrid
} from './styles'

interface Assistant {
	height: string | number
	title: string
	paragraphy: string
	buttonText: string
	image: { src: string }
	goToForm: () => void
}

const Assistant = () => {
	const { state, dispatch } = useContext(store)
	// @ts-ignore
	initUserSession(state, dispatch)
	const [loading, setLoading] = useState(false)
	const [showMessage, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState<IModalMessage>()

	const [assistantList, setAssistantList] = useState([])
	const [showModal, setShowModal] = useState({ open: false, assistant: null })
	// @ts-ignore
	const [showModalConfirm, setShowModalConfirm] = useState({
		open: false,
		message: '',
		onConfirm: null,
		assistant: { name: '', id: '' }
	})

	const data = useStaticQuery(query)
	const {
		title,
		link_home_page,
		button_register,
		paragraph,
		paragraph_two
	} = data.saudeDigital.metadata.assistant

	useEffect(() => {
		fetchAssistant()
	}, [state.session.cpf])

	useEffect(() => {
		const timer = window.setInterval(() => {
			if (!showModalConfirm.open && !showModal.open) fetchAssistant()
		}, 30000)
		return () => {
			window.clearInterval(timer)
		}
	}, [state, showModalConfirm.open, showModal.open])

	const handleConfirmDelete = useCallback(
		(assistant: any) => () => {
			setShowModalConfirm({
				open: true,
				message: 'DESATIVAR',
				// @ts-ignore
				onConfirm: () => handleDelete(assistant),
				assistant
			})
		},
		[]
	)
	const handleConfirmReactivate = useCallback(
		(assistant: any) => () => {
			setShowModalConfirm({
				open: true,
				message: 'REATIVAR',
				// @ts-ignore
				onConfirm: () => handleReactivate(assistant),
				assistant
			})
		},
		[]
	)

	const handleDelete = (assistant: IAssistant) => {
		setLoading(true)
		// @ts-ignore
		deleteSecretary({ secretaryId: assistant.id })
			.then((response: any) => {
				setLoading(false)
				if (response.status < 207) {
					setShowModalConfirm({
						open: false,
						message: '',
						onConfirm: null,
						assistant: { name: '', id: '' }
					})
					setMessageToShow({
						title: 'Pronto',
						message: 'Desativado com sucesso',
						buttonText: 'Voltar'
					})
					setShowMessage(true)
					fetchAssistant()
				} else {
					setMessageToShow({
						title: 'Pedimos desculpa',
						message: response.date.message,
						buttonText: 'Ok, entendi'
					})
					setShowMessage(true)
				}
			})
			.catch(e => {
				setMessageToShow({
					title: 'Pedimos desculpa',
					message: JSON.stringify(e),
					buttonText: 'Ok, entendi'
				})
				setShowMessage(true)
			})
	}
	const handleReactivate = (assistant: IAssistant) => {
		setLoading(true)
		// @ts-ignore
		reactivateSecretary({ secretaryId: assistant.id })
			.then((response: any) => {
				setLoading(false)
				if (response.status < 207) {
					setShowModalConfirm({
						open: false,
						message: '',
						onConfirm: null,
						assistant: { name: '', id: '' }
					})
					setMessageToShow({
						title: 'Pronto',
						message: 'Reativado com sucesso',
						buttonText: 'Ok, entendi'
					})
					fetchAssistant()
				} else {
					setMessageToShow({
						title: 'Pedimos desculpa',
						message: response.data.message,
						buttonText: 'Ok, entendi'
					})
				}
				setShowMessage(true)
			})
			.catch(e => {
				setMessageToShow({
					title: 'Pedimos desculpa',
					message: JSON.stringify(e),
					buttonText: 'Ok, entendi'
				})
				setShowMessage(true)
			})
	}

	const handleCloseModal = useCallback(() => {
		setShowModal({ open: false, assistant: null })
	}, [])

	const handleCloseModalConfirm = useCallback(() => {
		setShowModalConfirm({
			open: false,
			message: '',
			onConfirm: null,
			assistant: { name: '', id: '' }
		})
	}, [])

	const handleNewAssistant = useCallback(
		assistant => () => {
			setShowModal({ open: true, assistant: assistant || null })
		},
		[]
	)

	const fetchAssistant = () => {
		if (state.session.cpf) {
			setLoading(true)
			// @ts-ignore
			getSecretaries({ doctorCpf: state.session.cpf })
				.then((response: any) => {
					setLoading(false)
					if (response) {
						if (response.status === 200) {
							const assistants = (response.data || []).map((item: any) => ({
								id: item.id,
								name: item.name,
								email: item.email,
								status: item.status,
								birthDay: item.birthDay,
								phone: item.phone,
								optins: item.optins,
								cpf: item.secretaryCpf,
								doctorCpf: item.doctorCpf
							}))
							setAssistantList(assistants)
						} else if (response.status === 204 && assistantList.length) {
							setAssistantList([])
						}
					}
				})
				.catch(e => {
					setMessageToShow({
						title: 'Pedimos desculpas',
						message: JSON.stringify(e)
					})
					setShowMessage(true)
				})
		}
	}

	const handleSubmitAssistant = useCallback(
		assistant => {
			setLoading(true)
			if (!assistant?.id) {
				postSecretaryCreation({ ...assistant })
					.then(response => {
						if (response.status <= 204) {
							setMessageToShow({
								title: 'Secretário(a) cadastrado(a)',
								message: `Agora, ${assistant.name} receberá por email os passos para confirmar o cadastro.`,
								buttonText: 'Ok, entendi'
							})
							setShowModal({ open: false, assistant: null })
						} else {
							setMessageToShow({
								title: 'Pedimos desculpas',
								message: response.data.message
							})
						}
						fetchAssistant()
					})
					.catch(e => {
						setMessageToShow({
							title: 'Pedimos desculpas',
							message: JSON.stringify(e)
						})
					})
					.finally(() => {
						setShowMessage(true)
						setLoading(false)
					})
			} else {
				// @ts-ignore
				updateSecretaryByDoctor({ assistant, doctorCpf: assistant.doctorCpf })
					.then(response => {
						if (response.status <= 204) {
							setMessageToShow({
								title: 'Secretário(a) atualizado(a)',
								message: `Agora, ${assistant.name} receberá por email os passos para confirmar o cadastro.`,
								buttonText: 'Ok, entendi'
							})
							setShowModal({ open: false, assistant: null })
						} else {
							setMessageToShow({
								title: 'Pedimos desculpas',
								message: response.data.message
							})
						}
						fetchAssistant()
					})
					.catch(e => {
						setMessageToShow({
							title: 'Pedimos desculpas',
							message: JSON.stringify(e)
						})
					})
					.finally(() => {
						setShowMessage(true)
						setLoading(false)
					})
			}
		},
		[state.session]
	)

	return (
		<ComponentWrapper>
			{loading && <Loader />}
			{showMessage && (
				<MessageModal
					onClose={setShowMessage}
					title={messageToShow?.title}
					message={messageToShow?.message}
					buttonText={messageToShow?.buttonText}
				/>
			)}
			{state.session.accessToken && (
				<TemplatePage>
					<WrapperGrid>
						<Col className='right' size='12'>
							<NoContentWrapper>
								<ColBreadCrumb size='6' tablet='12' flex>
									<Link id='butto-arrow-back' to='/'>
										<img src={Arrow} />
									</Link>
									<Span bold fontSize='32px/40px' padding='0 0 0 10px'>
										{title}
									</Span>
									<Span bold fontSize='12px/20px' sm='0'>
										<LinkPage to='/'>{link_home_page}</LinkPage>
									</Span>
									<Span bold fontSize='12px/20px' padding='0 5px' sm='0'>
										/
									</Span>
									<Span bold fontSize='12px/20px' sm='0'>
										{title}
									</Span>
								</ColBreadCrumb>
								<ColAssistant size='6' flex justifyContent='flex-end'>
									<Button
										id='button-register-secretary'
										width='auto'
										onClick={handleNewAssistant(null)}
										p='0 10px'
									>
										{button_register}
									</Button>
								</ColAssistant>
							</NoContentWrapper>
						</Col>
						<Col size='12'>
							<Grid rowGap='0'>
								{assistantList.length > 0 ? (
									assistantList.map((item: IAssistant, index: number) => (
										<Col key={`key-collapse-group-${index}`} size='12'>
											<Collapse
												onDelete={handleConfirmDelete(item)}
												onResend={handleSubmitAssistant}
												onEdit={handleNewAssistant(item)}
												onReactivate={handleConfirmReactivate(item)}
												{...item}
											/>
										</Col>
									))
								) : (
									<Col size='12'>
										<Span wrap fontSize='32px/40px' bold>
											{paragraph}
										</Span>
										<Span wrap fontSize='32px/40px' bold sm='0'>
											{paragraph_two}
										</Span>
									</Col>
								)}
							</Grid>
						</Col>
					</WrapperGrid>
				</TemplatePage>
			)}

			{showModalConfirm.open && (
				<Confirm
					turnOff={showModalConfirm.message === 'DESATIVAR'}
					setShowModal={handleCloseModalConfirm}
					onConfirm={showModalConfirm.onConfirm}
				>
					Tem certeza de que deseja{' '}
					<Span fontSize='16px/24px' bold>
						{showModalConfirm.message}
					</Span>{' '}
					{showModalConfirm.assistant.name}?
				</Confirm>
			)}
			{showModal.open && (
				<AssistantModal
					onClose={handleCloseModal}
					onSubmit={handleSubmitAssistant}
					{...showModal.assistant}
				/>
			)}
		</ComponentWrapper>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				assistant: secretariado {
					title
					link_home_page: link_inicio
					button_register: botao_cadastra
					paragraph: paragrafo
					paragraph_two: segundo_paragrafo
				}
			}
		}
	}
`

export default Assistant
