const isValidCPF = (strCPF: string) => {
	let Soma
	let i
	let Resto
	Soma = 0

	let n = 0

	while (n < 10) {
		const str = n.toString()
		if (strCPF === str.repeat(11)) return false

		n++
	}

	for (i = 1; i <= 9; i++) Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i)
	Resto = (Soma * 10) % 11

	if (Resto === 10 || Resto === 11) Resto = 0
	if (Resto !== parseInt(strCPF.substring(9, 10))) return false

	Soma = 0
	for (i = 1; i <= 10; i++) Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i)
	Resto = (Soma * 10) % 11

	if (Resto === 10 || Resto === 11) Resto = 0
	if (Resto !== parseInt(strCPF.substring(10, 11))) return false
	return true
}

export default isValidCPF
