import React, { useEffect, useState } from 'react'

import { formatCPF, formatPhone, validateEmail } from '@cuidardigital/commons/utils/masks'

import ShowModal from '@cuidardigital/commons/components/Modal'
import IAssistant from '@cuidardigital/commons/types/IAssistant'
import isValidCPF from '@cuidardigital/commons/utils/cpf'

import { graphql, useStaticQuery } from 'gatsby'

import {
	BackButton,
	DisplayFlex,
	FormWrapper,
	InputElement,
	InputError,
	InputLabel,
	InputWrapper,
	ModalSubtitle,
	ModalTitle,
	ModalWrapper,
	SubmitButton
} from './styles'
import { Span } from '../../superSetCommons'

interface IModalObj {
	title?: string
	message: string
	buttonText?: string
}
interface PropsAssistant {
	onClose: () => void
	onSubmit: (a: IAssistant) => void
	id?: string
	name?: string
	email?: string
	cpf?: string
	phone?: string
}

const NewAssistantComponent: React.FC<PropsAssistant> = ({
	onClose,
	onSubmit,
	id,
	name,
	email,
	cpf,
	phone,
	...others
}) => {
	const [formValues, setFormValues] = useState({
		name: name || '',
		email: email || '',
		cpf: cpf || '',
		phone: phone || ''
	})
	const [errors, setErrors] = useState({ name: false, email: false, cpf: false, phone: false })
	const [formIsWrong, setFormIsWrong] = useState(true)

	useEffect(() => {
		const wrong = Object.values(errors).some(b => b) || Object.values(formValues).some(v => !v)
		setFormIsWrong(wrong)
	}, [errors])

	const handleFormSubmit = () => {
		onSubmit({ id, ...others, ...formValues })
	}

	const formSubmit = () => {
		if (!formIsWrong) {
			handleFormSubmit()
		}
	}

	const closeModal = () => {
		onClose()
	}

	const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { name } = formValues
		if (name !== value) setFormValues({ ...formValues, name: value })
		setErrors({ ...errors, name: value.length === 0 })
	}

	const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { email } = formValues
		if (email !== value) setFormValues({ ...formValues, email: value })
		setErrors({ ...errors, email: validateEmail(value) })
	}

	const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const formattedValue = formatPhone(value)
		setFormValues({ ...formValues, phone: formattedValue })
		setErrors({ ...errors, phone: formattedValue.length > 14 && formattedValue.length < 15 })
	}

	const handlePhoneBlur = () => {
		const { phone } = formValues
		setErrors({ ...errors, phone: phone.length !== 15 })
	}

	const handleCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { cpf } = formValues
		if (cpf !== value) {
			const newCpf = formatCPF(value)
			setFormValues({ ...formValues, cpf: newCpf })
			setErrors({
				...errors,
				cpf: !isValidCPF(
					value
						.replace('.', '')
						.replace('.', '')
						.replace('-', '')
				)
			})
		}
	}

	const data = useStaticQuery(query)
	const {
		title,
		paragraph,
		assistant_name,
		cpf_assistant,
		email_assistant,
		assistant_phone,
		button_back
	} = data.saudeDigital.metadata.assistant

	return (
		<ShowModal onClose={closeModal}>
			<ModalWrapper>
				<ModalTitle wrap bold fontSize='32px/40px'>
					{title}
				</ModalTitle>
				<ModalSubtitle wrap fontSize='16px/24px'>
					{paragraph}
				</ModalSubtitle>
				<FormWrapper>
					<InputWrapper>
						<InputLabel fontSize='14px/24px'>{assistant_name}</InputLabel>
						<InputElement
							withAllBorder
							p='5px 10px'
							id='new-assistant-name'
							placeholder='Nome completo'
							value={formValues.name}
							onChange={handleName}
							error={errors.name}
							type='text'
						/>
						{errors.name && <InputError fontSize='12px/18px'>Nome é obrigatório</InputError>}
					</InputWrapper>
					<InputWrapper>
						<InputLabel fontSize='14px/24px'>{cpf_assistant}</InputLabel>
						<InputElement
							withAllBorder
							p='5px 10px'
							id='new-assistant-cpf'
							placeholder='000.000.000-00'
							value={formValues.cpf}
							onChange={handleCpf}
							error={errors.cpf}
							disabled={!!name}
						/>
						{errors.cpf && <InputError fontSize='12px/18px'>Cpf inválido</InputError>}
					</InputWrapper>
					<DisplayFlex>
						<InputWrapper width='calc(50% - 12px)'>
							<InputLabel fontSize='14px/24px'>{email_assistant}</InputLabel>
							<InputElement
								withAllBorder
								p='5px 10px'
								id='new-assistant-email'
								placeholder='E-mail do(a) secretario(a)'
								value={formValues.email}
								onChange={handleEmail}
								error={errors.email}
								type='email'
							/>
							{errors.email && <InputError fontSize='12px/18px'>E-mail inválido</InputError>}
						</InputWrapper>
						<InputWrapper width='calc(50% - 12px)'>
							<InputLabel fontSize='14px/24px'>{assistant_phone}</InputLabel>
							<InputElement
								withAllBorder
								p='5px 10px'
								id='new-assistant-telephone'
								placeholder='(XX) XXXXX-XXXX'
								value={formValues.phone}
								onChange={handlePhone}
								onBlur={handlePhoneBlur}
								error={errors.phone}
								// disabled={name ? true : false}
							/>
							{errors.phone && <InputError fontSize='12px/18px'>Telefone inválido</InputError>}
						</InputWrapper>
					</DisplayFlex>
					<BackButton id='button-back-secretary' onClick={closeModal}>
						<Span fontSize='16px/24px' bold color='primary'>
							{button_back}
						</Span>
					</BackButton>
					<SubmitButton
						id='button-create-secretary'
						onClick={formSubmit}
						disabled={formIsWrong}
						error={formIsWrong}
					>
						<Span fontSize='16px/24px' bold color={!formIsWrong && 'ice'}>
							{name ? 'Finalizar' : 'Criar'}
						</Span>
					</SubmitButton>
				</FormWrapper>
			</ModalWrapper>
		</ShowModal>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				assistant: secretariado {
					title: novo_secretario
					paragraph: paragrafo_modal
					assistant_name: nome
					cpf_assistant: cpf
					email_assistant: email
					assistant_phone: telefone
					button_back: botao_voltar
				}
			}
		}
	}
`

export default NewAssistantComponent
