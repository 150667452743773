import Modal from '@cuidardigital/commons/components/Modal'
import React from 'react'
import styled from 'styled-components'
import { Button, ButtonReturn, SubmitButtonRed } from '../superSetCommons'
import { ModalSubtitle as Subtitle, ModalTitle, ModalWrapper } from './messageModal'

const ModalSubtitle = styled(Subtitle)`
	margin-bottom: 8vh;
`

const SubmitButton = styled(Button)`
	margin: 32px 0 0 4%;
	width: 48%;
`

const Confirm = ({ setShowModal, children, onConfirm, turnOff }) => {
	const closeModal = () => {
		setShowModal(false)
	}

	return (
		<Modal maxWidth='420px' padTop='20vh' zIndex='11' onClose={closeModal}>
			<ModalWrapper>
				<ModalTitle wrap bold fontSize='32px/40px'>
					{turnOff ? 'Desativar' : 'Reativar'} secretário(a)?
				</ModalTitle>
				<ModalSubtitle wrap fontSize='16px/24px'>
					{children}
				</ModalSubtitle>

				<ButtonReturn id='button-modal-confirm-cancel' onClick={closeModal}>
					Cancelar
				</ButtonReturn>
				<SubmitButton id='button-modal-confirm' half onClick={onConfirm}>
					Confirmar
				</SubmitButton>
			</ModalWrapper>
		</Modal>
	)
}

export default Confirm
