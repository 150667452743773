import styled from 'styled-components'

import {
	Button as SuperButton,
	ButtonReturn as SuperButtonReturn,
	InputElement as IE,
	InputError as SuperInputError,
	InputLabel as SuperInputLabel
} from '../../superSetCommons'

import { ModalSubtitle as Subtitle, ModalTitle as Title, ModalWrapper as Wrapper } from '../messageModal'

interface ISubmit {
	error?: boolean
}

interface IInputElement {
	margin?: string
	error?: boolean
	width?: string
}

interface IInputWrapper {
	width?: string
	margin?: string
}

export const BackButton = styled(SuperButtonReturn)``

export const InputError = styled(SuperInputError)``

export const InputLabel = styled(SuperInputLabel)``

export const ModalWrapper = styled(Wrapper)``

export const ModalTitle = styled(Title)`
	text-align: left;
`

export const ModalSubtitle = styled(Subtitle)`
	text-align: left;
`

export const FormWrapper = styled.div``

export const InputElement = styled(IE)`
	margin-top: 2px;
	width: 100%;
`

export const SubmitButton = styled(SuperButton)<ISubmit>`
	margin: 32px 0 0 4%;
	width: 48%;
`

export const InputWrapper = styled.div<IInputWrapper>`
	width: ${props => (props.width ? props.width : '100%')};
	text-align: left;
	margin: ${props => (props.margin ? props.margin : '14px 0 0')};
`

export const DisplayFlex = styled.div`
	display: flex;
	justify-content: space-between;
`
