import { Link } from 'gatsby'
import styled from 'styled-components'

import { Button as SuperButton, Span as SuperSpan } from '../superSetCommons'

import { Col as CommonColumn, Grid as CommonGrid } from '@cuidardigital/commons/components/Grid/grid'

interface IWrapper {
	height?: string | number
	mt?: string | number
}

interface IButton {
	width?: string | number
}

export const Button = styled(SuperButton)<IButton>``
export const Span = styled(SuperSpan)`
	margin: auto 0;
`

export const ComponentWrapper = styled.div<IWrapper>`
	min-height: 100vh;
	background: #f7f7f7 0% 0% no-repeat padding-box;
	width: 100%;
	box-shadow: 0px 0px 10px #00000026;
	border-radius: 8px;
	opacity: 1;
	margin-top: ${({ mt }: IWrapper) => mt + 'px'};
`

export const WrapperGrid = styled(CommonGrid)`
	width: 100%;
	box-shadow: 0px 0px 10px #00000026;
	border-radius: 8px;

	.left {
		padding-top: 38px;

		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 0px 10px #00000026;
	}
	.right {
		padding: 0 40px 10px;
		@media (max-width: 480px) {
			padding: 0 10px 5px;
		}
	}
`

export const NoContentWrapper = styled(CommonGrid)`
	padding: 0 10px 5px;
	margin: 38px 0 0;
	@media (min-width: 481px) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	@media (max-width: 480px) {
		margin: 18px 0 0;
	}
`

export const ColAssistant = styled(CommonColumn)`
	h1 {
		margin: 0;
		padding-left: 2%;
	}

	img {
		height: 38px;
		width: 100%;
	}

	@media (min-width: 481px) {
		width: 37%;
	}
`

export const ColBreadCrumb = styled(ColAssistant)`
	@media (min-width: 481px) {
		width: 80%;
	}
`

export const LinkPage = styled(Link)`
	margin: 10px 0 10px 10px;
	text-decoration: underline;
	color: ${props => props.theme.colors.font_dark};
`
